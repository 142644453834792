import gql from 'graphql-tag'
import React from 'react'
import { Roles, useMeAdminQuery } from '../generated/graphql'
import { useLogout } from '../lib/auth'

export const UserContext = React.createContext({
  loggedIn: false,
  isSuperAdmin: false,
  isAdmin: false,
  isAnon: false,
  isUploader: false,
  isSales: false,
  isModerator: false,
  isChannelCollaborator: (id: string) => !!id,
})

export const UserProvider = (props: { children: JSX.Element }) => {
  const { loading, data, error } = useMeAdminQuery()
  const logout = useLogout(true)
  const loggedIn = !!(data && data.meAdmin && data.meAdmin._id)
  const isSuperAdmin = !!(data && data.meAdmin && data.meAdmin.isAdmin)
  const isRole = (role: Roles) =>
    !!(data && data.meAdmin && data.meAdmin.roles && data.meAdmin.roles.indexOf(role) !== -1)
  const isAdmin = isRole(Roles.Admin)
  const isUploader = isRole(Roles.Uploader)
  const isSales = isRole(Roles.Sales)
  const isAnon = isRole(Roles.Anon)
  const isModerator = isRole(Roles.Moderator)
  const isChannelCollaborator = (id: string) =>
    !!(
      data &&
      data.meAdmin &&
      isRole(Roles.Uploader) &&
      data.meAdmin.collaboratingChannels &&
      data.meAdmin.collaboratingChannels.map(({ _id }) => _id).indexOf(id) !== -1
    )

  if (loading) return null
  if (error) logout()

  return (
    <UserContext.Provider
      value={{
        loggedIn,
        isAnon,
        isSuperAdmin,
        isAdmin,
        isUploader,
        isSales,
        isModerator,
        isChannelCollaborator,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export const ME_ADMIN = gql`
  query MeAdmin {
    meAdmin {
      _id
      email
      isAdmin
      displayName
      roles
      collaboratingChannels {
        _id
      }
    }
  }
`
